body {
    -webkit-font-smoothing: antialiased;
    color: var(--textColor);
    font-family: var(--baseFontFamily);
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 1.5;
}

button,
input {
    font-family: var(--baseFontFamily);
    font-size: 16px;
    line-height: 1.5;
}

a {
    color: var(--textColor);
    text-decoration: none;
    border-bottom: 2px solid var(--textColor);

    &:hover {
        border-bottom-width: 4px;
    }
}

select {
    font-family: var(--baseFontFamily);
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1 {
    @mixin h1;
}

h2 {
    @mixin h2;
}

h3 {
    @mixin h3;
}

h4 {
    @mixin h4;
}

blockquote {
    margin: 24px 0;
}

p {
    margin: 0 0 24px;
}

figure img {
    display: block;
}

figcaption {
    @mixin caption;
    display: block;
    margin: 8px 0 16px;
}
