.mce-content-body {
    padding: 16px;

    &:first-child {
        margin-top: 0;
    }

    & figure {
        margin-left: 0;
        margin-right: 0;
    }

    & .Richtext__blockquote {
        background: #f5f5f5;
        position: relative;
        padding: 32px 16px 24px;

        &::before {
            content: '\201C';
            font-size: 32px;
            line-height: 32px;
            position: absolute;
            left: 16px;
            top: 12px;
        }

        & p:last-child {
            margin-bottom: 0;
        }
    }

    & img {
        width: 50%;
    }

    & .ImageFullWidth {
        width: 100%;
    }

    & .ImageBeyondWrapper {
        width: calc(100% + 48px);
        margin-left: -24px;
    }

    & p + .epi-contentfragment {
        margin-bottom: 24px;
    }

    & .Richtext__ol {
        margin: 40px 0;
        padding-left: 32px;

        & li {
            margin: 8px 0;
        }
    }

    & .Richtext__ul {
        @mixin list;
        margin: 40px 0;
    }

    & .Richtext__table {
        margin: 40px 0;
        width: 100%;
    }
}
