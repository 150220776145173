/* stylelint-disable selector-class-pattern */

/* These classes are used on SectionPage__list element */

.layout1col > * {
    @media (--tablet) {
        lost-column: 1;
    }
}

.layout2col > * {
    @media (--tablet) {
        lost-column: 1/2;
    }
}

.layout3col > * {
    @media (--tablet) {
        lost-column: 1/3;
    }
}

.layout4col > * {
    @media (--tablet) {
        lost-column: 1/2;
    }

    @media (--desktop) {
        lost-column: 1/4;
    }
}

/* stylelint-enable selector-class-pattern */
