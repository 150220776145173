.PageLayout__grid {
    @media (--tablet) {
        width: calc((100% + var(--gutterWidth)) * var(--layoutGridSpan, 12) / 12 - var(--gutterWidth));
        margin-left: calc((100% + var(--gutterWidth)) * var(--layoutGridOffset, 0) / 12) !important;
    }
}

.PageLayout__content {
    @media (--tablet) {
        width: calc((100% + var(--gutterWidth)) * var(--layoutContentSpan, 12) / 12 - var(--gutterWidth));
        margin-left: calc((100% + var(--gutterWidth)) * var(--layoutContentOffset, 0) / 12) !important;
    }
}
