@import-normalize;

body {
    margin: 0;
    overflow: hidden scroll;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* stylelint-disable-next-line selector-class-pattern */
.wrapper {
    @mixin wrapper;
}

img {
    max-width: 100%;
}

figure {
    margin: 24px 0;
}

figcaption span {
    display: block;
}

fieldset {
    min-width: 0;
    border: none;
    margin: 0;
    padding: 0;
}

input {
    border-radius: 0;
    box-shadow: none;
}

button {
    cursor: pointer;
    border-radius: 0;
    box-shadow: none;

    &:focus:not(.focus-visible) {
        outline: none;
    }

    &:active {
        color: currentColor;
    }
}

textarea {
    font-family: inherit;
}

strong {
    font-weight: 500;
}

/* stylelint-disable-next-line selector-class-pattern */
.visuallyHidden {
    @mixin visuallyHidden;
}

/* stylelint-disable-next-line selector-class-pattern */
.mobileOnly {
    @media (--tablet) {
        display: none !important;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.tabletAndUp {
    @media (--mobileOnly) {
        display: none !important;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.belowDesktop {
    @media (--desktop) {
        display: none !important;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.desktopAndUp {
    @media (--belowDesktop) {
        display: none !important;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.safariModalFix {
    @mixin safariModalFullHeight;
}

/* stylelint-disable-next-line selector-class-pattern */
.extendedWrapper {
    @mixin extendedWrapperVariables;
}

/* stylelint-disable-next-line selector-class-pattern */
.myPageWrapper {
    @mixin myPageWrapperVariables;
}

#chat-widget-container {
    z-index: 1060 !important;

    & > iframe {
        @mixin hideOnPrint;

        @media (--mobileOnly) {
            top: auto !important;
            right: 0 !important;
            left: 0 !important;
            bottom: 0 !important;
            max-height: calc(100vh - var(--headerMobileHeight) - var(--infoBannerMobileHeight));
        }
    }
}

/* stylelint-disable-next-line selector-id-pattern */
#epi-quickNavigator {
    @mixin hideOnPrint;
}
