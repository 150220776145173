.ImageRegular,
.ImageBeyondWrapper,
.ImageFullWidth {
    margin-top: 40px;
    margin-bottom: 40px;

    & picture {
        position: relative;
        display: block;
    }

    & img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
}

.ImageFullWidth,
.ImageBeyondWrapper {
    @media (--tablet) {
        margin-top: 64px;
        margin-bottom: 64px;
    }
}

.ImageRegular,
.ImageFullWidth {
    margin-left: -32px;
    margin-right: -32px;

    @media (--tablet) {
        margin-left: 0;
        margin-right: 0;
    }

    & figcaption {
        padding: 0 32px;

        @media (--tablet) {
            padding: 0;
        }
    }
}

figure {
    &.ImageRegular,
    &.ImageBeyondWrapper,
    &.ImageFullWidth {
        & img {
            margin-bottom: 0;
        }

        & figcaption {
            @media (--tablet) {
                z-index: 1;
                position: relative;
            }
        }
    }
}

.Richtext {
    & .ImageRegular {
        @media (--tablet) {
            --layoutContentOffset: 0;
            --layoutContentSpan: 6;
        }

        @media (--desktop) {
            --layoutContentOffset: 1;
            --layoutContentSpan: 5;
        }

        & figcaption {
            @media (--tablet) {
                --layoutContentOffset: 0;
                --layoutContentSpan: 12;
            }
        }
    }

    & .ImageFullWidth {
        @media (--tablet) {
            --layoutContentOffset: 0;
            --layoutContentSpan: 12;
        }

        & figcaption {
            @media (--tablet) {
                --layoutContentOffset: 7;
                --layoutContentSpan: 5;
            }

            @media (--desktop) {
                --layoutContentOffset: 8;
                --layoutContentSpan: 4;
            }
        }
    }

    & .ImageBeyondWrapper {
        @media (--tablet) {
            --layoutContentOffset: 7;
            --layoutContentSpan: 5;
        }

        @media (--desktop) {
            --layoutContentOffset: 8;
            --layoutContentSpan: 4;
        }
    }
}

.Richtext--wide {
    & .ImageRegular {
        @media (--tablet) {
            --layoutContentOffset: 1;
            --layoutContentSpan: 9;
        }
    }

    & .ImageFullWidth {
        & figcaption {
            @media (--tablet) {
                --layoutContentOffset: 1;
                --layoutContentSpan: 9;
            }
        }
    }

    & .ImageBeyondWrapper {
        @media (--tablet) {
            --layoutContentOffset: 1;
            --layoutContentSpan: 9;
        }
    }
}
