@font-face {
    font-family: Graphik;
    src: url('../Fonts/GraphikRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Graphik;
    src: url('../Fonts/GraphikMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Graphik;
    src: url('../Fonts/GraphikSemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Graphik;
    src: url('../Fonts/GraphikBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Produkt;
    src: url('../Fonts/ProduktSemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
