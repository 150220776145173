.Richtext {
    & video {
        width: 100%;
    }

    & .Richtext__wrapper {
        @mixin wrapper;
    }
}

.Richtext__block {
    margin: var(--richtextBlockVerticalMargin) 0;

    &:last-child {
        margin-bottom: 0;
    }

    & + .Richtext__block {
        margin-top: calc(-1 * var(--richtextBlockVerticalMargin));
    }
}
