.Page {
    & :target::before {
        content: '';
        display: block;
        height: calc(var(--headerMobileHeight) + 16px);
        margin-top: calc(-1 * var(--headerMobileHeight) - 16px);

        @media (--tablet) {
            height: calc(var(--headerHeight) + 16px);
            margin-top: calc(-1 * var(--headerHeight) - 16px);
        }
    }
}

.Page__wrapper {
    @mixin wrapper;
}

.Page__layout {
    @mixin wrapper;
    lost-flex-container: row;
}

.Page__header {
    @media (--desktop) {
        lost-column: 1/2;
    }

    &:nth-child(n) {
        margin-left: 0;
    }

    & > *:last-child:not(.Page__title) {
        margin-bottom: 0;
    }
}

.Page__body {
    margin-top: 64px;
    margin-bottom: 64px;

    &:last-child {
        margin-bottom: 0;
    }
}

.Page__title {
    @mixin h2;
    margin: 16px 0 32px;

    @media (--tablet) {
        margin-top: 40px;
    }
}

.Page__intro {
    @mixin h4;
    margin: 32px 0;
    font-weight: 500;

    & > *:last-child {
        margin-bottom: 0;
    }
}

.Page__hero {
    padding: 16px 0 24px;

    & .Page__heading {
        @mixin h2;
        margin: 0;
    }
}

.Page__heroInner {
    @mixin wrapper;
}

.Page__contentArea {
    & .ContentList {
        overflow: hidden;
    }
}
