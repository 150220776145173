.RichtextContent,
.Richtext__content,
.mce-content-body {
    @media (--tablet) {
        position: relative;
        z-index: 1;
    }

    & form picture {
        margin-bottom: 0;
    }

    & em {
        font-style: italic;
    }

    & strong {
        font-weight: 500;
    }

    & iframe {
        width: 100%;
        border: none;
    }

    & p {
        margin-top: 0;
    }

    & h2 {
        @mixin h3;
    }

    & h3 {
        @mixin h4;
    }

    & h2,
    & h3,
    & h4 {
        @media print {
            page-break-after: avoid;
        }
    }

    & a:not([data-owned]) {
        @mixin link;
    }

    & > * + h2,
    & > * + h3,
    & > * + h4 {
        margin-top: 64px;
        margin-bottom: 32px;
    }

    & .Richtext__ol,
    & > ol,
    & > ol ol {
        margin: 40px 0;
        padding-left: 32px;

        & li {
            margin: 8px 0;
        }
    }

    & .Richtext__ul,
    & > ul,
    & > ul ul {
        @mixin list;
        margin: 40px 0;
        text-align: initial;
    }

    & .Richtext__ul,
    & .Richtext__ol,
    & .Richtext__table,
    & > ul,
    & > ul ul,
    & > ol,
    & > ol ol,
    & > table,
    & > table table {
        @media print {
            page-break-inside: avoid;
        }
    }

    & p + .Richtext__ul,
    & > p + ul {
        margin-top: 0;
    }

    & .Richtext__table,
    & > table,
    & > table table {
        margin: 40px 0;
        width: 100%;

        & tr {
            & th:first-child,
            & td:first-child {
                padding-left: 0;
            }

            & th:last-child,
            & td:last-child {
                padding-right: 0;
            }
        }

        & th {
            padding: 6px 4px;
            font-weight: 500;
            text-align: left;

            @media (--tablet) {
                padding: 12px 8px;
            }
        }

        & td {
            padding: 4px;

            @media (--tablet) {
                padding: 8px;
            }
        }
    }

    & .Richtext__blockquote {
        @mixin h3;
        background: var(--redDarken40LegacyColor);
        color: var(--whiteBaseColor);
        padding: 112px 24px 64px;
        font-style: italic;
        position: relative;
        margin: 40px -32px;

        &::before {
            content: '';
            display: block;
            width: 64px;
            height: 64px;
            background-color: var(--redLighten40LegacyColor);
            mask: svg-load('Alphabet/quote.svg') no-repeat center;
            position: absolute;
            top: 24px;
            left: 32px;
        }

        @media (--tablet) {
            width: calc(100% * 6 / 5);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.RichtextContent--dark {
        & a:not([data-owned]) {
            @mixin linkUnderline var(--blackLighten20Color), var(--blackLighten40Color);
        }
    }

    &.RichtextContent--light {
        color: var(--whiteBaseColor);

        & a:not([data-owned]) {
            @mixin linkUnderline var(--support02HKBaseColor), var(--support02HKLighten20Color);
            color: var(--whiteBaseColor);
        }

        & ul li::before {
            background: var(--whiteBaseColor);
        }
    }
}

.Richtext {
    & .PageLayout__content.Richtext__content {
        @media (--tablet) {
            --layoutContentOffset: 0;
            --layoutContentSpan: 6;
        }

        @media (--desktop) {
            --layoutContentOffset: 1;
            --layoutContentSpan: 5;
        }
    }
}

.Richtext--narrow {
    & .PageLayout__content.Richtext__content {
        & .Richtext__blockquote {
            @media (--tablet) {
                width: 100%;
            }
        }
    }
}

.Richtext--wide {
    & .PageLayout__content.Richtext__content {
        @media (--tablet) {
            --layoutContentOffset: 1;
            --layoutContentSpan: 9;
        }

        & .Richtext__blockquote {
            @media (--tablet) {
                width: calc(100% * 7 / 9);
            }
        }
    }
}
