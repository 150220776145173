/* stylelint-disable selector-class-pattern */

/* These class names are 3rd party ones */

.Form {
    & input[type='text'],
    & input[type='email'],
    & input[type='tel'],
    & input[type='search'],
    & input[type='password'],
    & select,
    & textarea {
        @mixin input;
    }

    & select {
        @mixin select;
    }
}

.Form__field {
    margin-bottom: 32px;
}

.Form__fieldDisabled {
    & .Form__label {
        color: var(--blackLighten30Color);
    }
}

.Form__fieldError {
    & input[type='text'],
    & input[type='email'],
    & input[type='search'],
    & input[type='tel'],
    & input[type='password'],
    & select {
        border-color: var(--primaryHKBaseColor);
    }
}

.Form__fieldRounded {
    & input[type='text'],
    & input[type='email'],
    & input[type='search'],
    & input[type='tel'],
    & input[type='password'],
    & select,
    & textarea {
        @mixin themeRoundedCornersSmall;
    }
}

.Form__columns {
    @media (--desktop) {
        display: flex;
        flex-wrap: wrap;
    }

    & > div {
        @media (--desktop) {
            lost-column: 1/2;
        }
    }
}

.Form__label {
    font-weight: 600;
    margin-bottom: 8px;
}

.Form__label--secondary {
    font-size: 14px;
    font-weight: normal;
}

.Form__errorMessage {
    @mixin fieldError;
    margin-top: 8px;
}

.Form__checkbox,
.Form__fieldCheckbox {
    @mixin checkbox;
    margin: 0;

    & .Form__errorMessage {
        margin-left: -40px;
        display: block;
        margin-top: 10px;
    }

    &.Form__fieldError {
        & input[type='checkbox'] + label::before {
            border-color: var(--primaryHKBaseColor);
        }
    }
}

.Form__radioButtonGroupList {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
        border-bottom: 1px solid var(--blackLighten40Color);
        padding-top: 28px;
        padding-bottom: 28px;

        &:first-of-type {
            padding-top: 0;
            border-bottom: 1px solid var(--blackLighten40Color);
        }
    }
}

.Form__radioButtonGroup {
    margin-bottom: 32px;

    & legend {
        margin-bottom: 8px;
    }

    & fieldset:disabled {
        & legend.Form__label {
            color: var(--blackLighten30Color);
        }
    }
}

.Form__radioButtonGroupDescription {
    font-size: 12px;
    line-height: 16px;

    @media (--desktop) {
        width: 422px;
    }
}

.Form__radio,
.Form__fieldRadioButton {
    position: relative;
    margin: 0;
    padding: 12px 16px 12px 48px;

    &:not(:last-of-type) {
        margin-bottom: 8px;
    }

    & label {
        font-weight: 400;
    }

    & input[type='radio'] {
        @mixin onFocus {
            & + label {
                &::before {
                    @mixin focusVisible;
                }
            }
        }
        position: absolute;
        left: -9999px;

        &:hover + label::before {
            border-color: var(--blackLighten10Color);
        }

        &:focus + label::before {
            border-color: var(--blackLighten10Color);
        }

        &:checked + label {
            &::before {
                border-color: var(--blackLighten10Color);
            }

            &::after {
                @mixin fadeInAnimation 200ms;
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                left: 18px;
                top: 18px;
                background-color: var(--blackLighten10Color);
            }
        }

        &:disabled + label {
            cursor: default;
            color: var(--blackLighten30Color);

            &::before {
                border-color: var(--blackLighten40Color);
            }

            &::after {
                background-color: var(--blackLighten40Color);
            }
        }

        & + label {
            cursor: pointer;
            margin-left: 8px;

            &::before {
                content: '';
                position: absolute;
                left: 12px;
                top: 12px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: var(--whiteBaseColor);
                border: 2px solid var(--blackLighten30Color);
                transition: border-color 0.2s ease-out;
            }
        }
    }
}

.Form__list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
        min-height: 48px;
        display: flex;
        align-items: center;
    }
}

.Form__buttons {
    display: flex;
    flex-direction: column;

    @media (--tablet) {
        flex-direction: row;
        justify-content: flex-end;
    }

    & > button {
        margin-bottom: 16px;
        min-width: auto;

        @media (--tablet) {
            margin-left: 8px;
            margin-bottom: 0;
            width: calc(50% - 4px);
        }

        @media (--desktop) {
            min-width: 168px;
        }

        &:first-child {
            @media (--tablet) {
                margin-left: 0;
            }
        }
    }
}

.Form__fieldTextArea {
    &.Form__fieldTextArea--autoGrow {
        & textarea {
            resize: none;
            overflow: hidden;
            height: auto;
            min-height: 48px;
        }
    }
}

.Form__fieldTextAreaCounter {
    @mixin p50;
    color: var(--blackLighten20Color);
    display: block;
}

/* the selector below is created on purpose */

.EPiServerForms.EPiServerForms {
    margin: 96px auto;
    clear: both;

    @media (--desktop) {
        max-width: 65.67%;
    }

    & li {
        padding: 0;
        display: block;
        list-style: none;
        margin: 10px 0 0;
    }

    & label,
    & legend,
    & .FormChoice--Image .Form__Element__Caption {
        font-weight: 500;
        margin: 0 0 8px;
        padding: 0;
        display: block;
    }

    & input[type='text'],
    & input[type='date'],
    & input[type='datetime'],
    & input[type='number'],
    & input[type='search'],
    & input[type='time'],
    & input[type='url'],
    & input[type='email'],
    & input[type='tel'],
    & textarea,
    & select {
        @mixin input;
    }

    & fieldset {
        border: none;
        padding: 0;
        margin: 0;
        min-width: 0;
    }

    & textarea {
        height: 100%;
    }

    & select {
        padding-top: 0;
        padding-bottom: 0;
    }

    & button,
    & input[type='submit'],
    & input[type='reset'] {
        cursor: pointer;
        outline: none;
        margin-right: 16px;

        @media (--tablet) {
            width: 200px;
        }
    }

    & .Form__Element {
        margin-bottom: 16px;
    }

    & .Form__MainBody {
        & .Form__Element__Caption {
            display: flex;
            min-height: 48px;
            width: 100%;
            align-items: flex-end;
        }

        & .FormSubmitButton {
            @mixin primaryButton;
            @mixin p30;
            padding: 12px 48px;
            min-width: 185px;
            margin-left: auto;
        }

        & input[type='reset'] {
            @mixin secondaryButton;
            padding: 10px 48px;
            margin-right: 0;

            @media (--tablet) {
                margin-right: 16px;
            }

            & + .FormSubmitButton {
                margin-left: 0;
            }
        }
    }

    & .Form__Status .hide {
        display: none;
    }

    & .Form__Description {
        margin-bottom: 48px;
    }

    & .Form__Element .FormChoice {
        & label {
            font-weight: 400;
        }
    }

    & .Form__Title {
        @mixin h2;
    }

    & .FormStep {
        margin-bottom: 64px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        @media (--tablet) {
            & > .FormResetButton {
                margin-left: auto;
            }

            & > .FormResetButton,
            & > .FormSubmitButton {
                max-width: 185px;
                flex-basis: 185px;
                align-self: flex-end;
            }
        }

        & > .Form__Element:not(.FormSubmitButton) {
            flex-basis: 100%;
            justify-content: flex-start;

            @media (--tablet) {
                flex-basis: calc(50% - 16px);
            }
        }

        & > .FormCaptcha,
        & > .FormParagraphText {
            flex-basis: 100%;
        }

        &.hide {
            display: none;
        }
    }

    & .FormRange {
        & .FormRange__Input {
            max-width: none;
        }

        & > span {
            display: flex;
        }

        & .FormRange__Min {
            padding-right: 8px;
        }

        & .FormRange__Max {
            padding-left: 8px;
        }
    }

    & .FormCaptcha {
        & input[type='text'].FormCaptcha__Input {
            margin-top: 16px;
            width: 50%;

            @media (--tablet) {
                width: 25%;
            }
        }

        & img {
            width: auto;
            display: block;
        }

        & .FormCaptcha__Refresh {
            @mixin secondaryButton;
            padding: 10px 48px;
            margin-bottom: 16px;
            margin-right: 0;
            display: block;
        }
    }

    & .Form__Status .Form__Success__Message {
        @mixin label;
        font-weight: 400;
        background: var(--blackLighten60LegacyColor);
        color: var(--textColor);
        padding: 32px;

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    & .FormStep__Title {
        width: 100%;
    }

    & .FormStep__Description {
        width: 100%;
    }

    & .Form__NavigationBar {
        @media (--mobileOnly) {
            flex-wrap: wrap;
        }

        & .Form__NavigationBar__ProgressBar {
            border: 1px solid var(--redBaseLegacyColor);
            margin: 0 16px;

            @media (--mobileOnly) {
                order: -1;
                width: 100%;
                margin: 0 0 32px;
            }

            & .Form__NavigationBar__ProgressBar--Progress {
                background: var(--redDarken20LegacyColor);
            }
        }

        & .Form__NavigationBar__Action.btnNext {
            @mixin primaryButton;
            @mixin p30;
            background-image: none;
            max-width: none;
            padding: 12px 48px;
            height: auto;
            width: auto;
            border-radius: 0;
            margin-right: 0;

            @media (--mobileOnly) {
                width: 100%;
            }
        }

        & .Form__NavigationBar__Action.btnPrev {
            @mixin secondaryButton;
            @mixin p30;
            background-image: none;
            max-width: none;
            padding: 10px 48px;
            height: auto;
            width: auto;
            border-radius: 0;
            margin-right: 0;

            @media (--mobileOnly) {
                width: 100%;
                margin-bottom: 16px;
            }
        }
    }
}

.FormTextbox,
.FormSelection,
.FormRange,
.FormFileUpload {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    & label {
        font-weight: 500;
        margin-bottom: 8px;
    }

    & input,
    & select,
    & textarea,
    & fieldset,
    & > span {
        width: 100%;
    }
}

.FormChoice {
    & img {
        width: 100px;
        margin: 8px 0;
    }
}

.Form__NavigationBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.FormTextbox.ValidationFail,
.FormSelection.ValidationFail {
    & input[type='text'],
    & input[type='date'],
    & input[type='datetime'],
    & input[type='number'],
    & input[type='search'],
    & input[type='time'],
    & input[type='url'],
    & input[type='email'],
    & select,
    & textarea {
        border: 1px solid var(--primaryHKBaseColor);
        color: var(--primaryHKBaseColor);
    }
}

.Form__Element__ValidationError {
    color: var(--primaryHKBaseColor);
    display: block;
    justify-self: flex-end;
    width: 80%;
    margin-top: 5px;
    font-size: 16px;
    font-weight: normal;
}

/* stylelint-enable selector-class-pattern */
